<template>
  <el-dialog
    title="解锁方式"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="480px"
  >
    <div class="btn-wrapper">
      <div class="item active" @click="handleBtnClick(0)">
        <i class="iconfufeizixun iconfont icon"></i>
        <span class="text">付费购买</span>
      </div>
      <div class="item" @click="handleBtnClick(1)">
        <i class="iconliwu iconfont icon" ></i>
        <span class="text">兑换码激活</span>
      </div>
    </div>

  </el-dialog>
</template>

<script>
import {createBuyOrder} from "@/api/api";

export default {
  name: "BuyApproach",
  data() {
    return {
      dialogVisible: false
    }
  },
  methods:{
    handleBtnClick(val) {
        this.$emit('click',val)
    }
  }
}
</script>

<style lang="stylus" scoped>
.btn-wrapper {
  display flex;
  justify-content center;
  align-items center

  .item {
    width: 96px;
    height: 96px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #eee;
    display flex;
    flex-direction column
    justify-content center
    align-items center
    color: rgba(0, 0, 0, 0.4);
    cursor pointer

    margin 0 40px

    .icon {
      font-size 32px
    }

    .text {
      font-size: 16px;
      line-height 1.5
      font-weight bold
      margin-top 5px
    }

  }

  .item.active {
    background: #F0F5FF;
    border: 1px solid #2F54EB;
    color: #2F54EB;

    .icon {
      color: #2F54EB;
    }

    .text {
      color: #2F54EB;
    }
  }
}

>>> .el-dialog .el-dialog__header {
  text-align center
  border-bottom none;
  padding-top 32px
}

>>> .el-dialog__body {
  padding 48px 0
}

</style>
