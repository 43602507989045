import { mapGetters, mapState } from "vuex";
let mixin = {
  computed: {
    ...mapState("ResultEditor", ["isGlobalDisableEdit"]),
    ...mapGetters("ResultEditor", ["isEditableForAction"]),
    isEditable() {
      return !this.isGlobalDisableEdit && this.isEditableForAction(this.$route.params.action);
    }
  }
};

export default mixin;
