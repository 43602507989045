<template>
  <div class="table-c">
    <table class="table 222">
      <thead>
        <tr>
          <th v-for="(label, index) in labels" :key="index">{{ label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in tableData" :key="index">
          <td v-for="(col, index2) in row" :key="index2">
            <span
              v-if="col == 'button{detail}'"
              class="clickable"
              @click="handleCellClick(index)"
              ><a href="#" @click.prevent="handleToggleDetailCLick" class="link">{{
                toggleLabel
              }}</a></span
            >
            <span v-else v-html="col"></span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
  },
  computed: {
    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
  },
  data() {
    return {};
  },
  methods: {
    handleCellClick(row) {
      this.$emit("cellClick", row);
    },
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    },
  },
};
</script>

<style lang="stylus" scoped>
.table-c {
  width: 6.7rem;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow-x: auto;

  td, th {
    text-align: left;
    font-size: 0.2rem;
    padding: 0.12rem 0.12rem;
    // border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    // vertical-align: top;
    // min-width: 100px;
    box-sizing: border-box;
    white-space: nowrap;
    min-width: 60px;
    max-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

.table tr:nth-child(2n-1) td {
  background: #fff;
}

.table thead tr > th:first-child,
.table tbody tr > td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}
</style>
