<template>
  <div>
    <RiskOverallBanner v-if="treeData.tag == 3"></RiskOverallBanner>
    <div class="box-wrapper">
      <div class="list" v-if="$route.query['fromAdmin'] != 1">
        <div class="item">
          <div class="item-array" v-for="(item, index) in summaryText">
            {{ item }}
          </div>
        </div>
      </div>
      <div class="list" v-else>
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="summaryTextInput"
          show-word-limit
          class="summaryTextarea"
          rows="8"
        >
        </el-input>
        <div class="btn-summary-click-wrapper">
          <button
            class="btn btn-primary btn-summary-click"
            @click="handleBtnSummaryClick"
          >
            保存
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import RiskOverallBanner from "./RiskOverallBanner";
import { addSummaryByTag, updateZS } from "@/api/api";
export default {
  props: ["bankName"],
  components: {
    RiskOverallBanner,
  },
  computed: {
    ...mapState("ResultEditor", ["overall", "treeData"]),
    summaryText() {
      return this.overall.pdfData && this.overall.pdfData.split(/\n/g);
    },
  },
  data() {
    return {
      summaryTextInput: "",
    };
  },
  mounted() {
    this.summaryTextInput = this.overall.pdfData;
  },
  methods: {
    switchTab(tabName) {
      this.$emit("switchTab", tabName);
    },
    getLabel(typeId) {
      switch (typeId) {
        case "RST_1":
        case "RST_2":
        case "RST_3":
        case "RST_4":
          return "增值税专用发票风险评估";
          break;
        case "SWFXPG":
          return "税务风险评估";
          break;
        case "CWFXPG":
        case "CWFXPG1":
          return "财务风险评估";
          break;
        case "AREA_TEXT":
          return "优惠政策提醒";
          break;
        default:
          return "";
      }
    },
    handleBtnSummaryClick() {
      const { contrastKey, dataText, id, orderNumber, sort, titleName } = this.overall;

      updateZS({
        contrastKey,
        dataText,
        id,
        orderNumber,
        pdfData: this.summaryTextInput,
        sort,
        titleName,
      }).then((res) => {
        this.$alert(res.msgInfo);
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
.btn-summary-click-wrapper{
  text-align right;
  .btn-summary-click{
    margin-top 15px;
  }
}

.box-wrapper {
  font-size: 0.28rem;
  color: #30345c;
  line-height: 0.36rem;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0.14rem;
  padding: 0.24rem;
  white-space:pre-line;

  .h1 {
    color: #666;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
  }

  strong {
    color: #2F54EB;
  }

  .list {

    .item {

      .link {
        color: #2F54EB;
        cursor: pointer;
      }

      .link:hover {
        text-decoration: underline;
      }

      .item-array {
        margin-bottom: 0.16rem;
        // width: 6.22rem;
        background: rgba(98, 147, 248, 0.08);
        border-radius: 0.08rem;
        border: 1px solid #6293F8;
        padding: 0.16rem 0.24rem;
        box-sizing: border-box;
      }
      .item-array:first-child {
        border: none;
        padding: none;
        background: none;
        margin-bottom: 0;
        padding: 0;
        padding-bottom: 0.16rem;
      }
      .item-array:nth-child(2) {
        border: none;
        padding: none;
        background: none;
        margin-bottom: 0;
        padding: 0;
        padding-bottom: 0.16rem;
      }
      .item-array:last-child {
        border: none;
        padding: none;
        background: none;
        margin-bottom: 0;
        padding: 0;
        padding-bottom: 0.16rem;
        color: rgba(43, 47, 88, 0.2);
      }
    }
  }
}
</style>
