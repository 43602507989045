<template>
  <div class="head-c">
    <div class="head" :id="id">
      <!-- <i
        :class="{'el-icon-arrow-down active':show,'el-icon-arrow-up':!show}"
        class="arrow-icon"
        @click="handleHeadClick"
      ></i> -->
      <slot name="title"></slot>
      <span class="subTitle">
        <slot name="subTitle"></slot>
      </span>
    </div>
    <transition name="fade">
      <div class="body" v-show="show">
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
//下拉展示组件
export default {
  props: {
    id: [String, Number],
  },
  data: function () {
    return {
      show: true,
    };
  },
  methods: {
    handleHeadClick() {
      this.show = !this.show;
    },
  },
};
</script>

<style lang="stylus" scoped>
.head-c {
  position: relative;
  padding-top: 0.24rem;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 0.14rem;
}

.head {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 600;
  color: #2B2F58;
  line-height: 0.44rem;
  padding-left: 0.24rem;
  height: 0.8rem;



  .subTitle {
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
    font-size: 14px;
  }

  .arrow-icon {
    position relative;
    top 3px;
    cursor: pointer;
    font-weight: bold;
    font-size: 20px;
    color: #2F54EB;
    margin-right 16px;
  }

  .arrow-icon :hover {
    color: rgba(47, 84, 235, 0.01);
  }

  .arrow-icon.active {
    color: #2F54EB;
  }
}

.fade-enter-active, .fade-leave-active {
}

.fade-enter, .fade-leave-to { /* .fade-leave-active below version 2.1.8 */
}
</style>
