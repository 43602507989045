<template>
  <div
    class="result-wrapper"
    id="result-wrapper"
    :class="{ 'result-wrapper-color': isYunyingVersion }"
  >
    <!-- 目录 -->
    <div @click="showMenuTree" class="title-menu">目录</div>
    <menu-h5 ref="menuTree"></menu-h5>
    <!-- 目录 -->
    <div class="title">{{ h5TitieName }}</div>
    <div class="result">
      <div v-if="dataLoaded">
        <OpsSection v-if="isYunyingVersion"></OpsSection>
        <div class="box-wrapper" v-if="!isYunyingVersion">
          <div class="title">
            <div class="tip"></div>
            <div>综合风险评估</div>
          </div>
          <TaxSummary @switchTab="switchTab" :bankName="$route.query.name"></TaxSummary>
        </div>

        <!--为园区时隐藏-->
        <template v-for="(aTreeData, index) in this.treeData">
          <div :key="index" class="box-wrapper">
            <div class="title" :class="{ displayNone: aTreeData.titleName == '' }">
              <div class="tip"></div>
              <div :id="'node' + aTreeData.id">
                {{ aTreeData.titleName }}
              </div>
            </div>
            <TabContent
              :key="`a_${index}_${aTreeData.titleName}`"
              :node="aTreeData"
            ></TabContent>
          </div>
        </template>
      </div>
    </div>
    <DataSafeGuaranteeDialog></DataSafeGuaranteeDialog>

    <!--选择版本-->
    <SelectVersion
      :isTryVersion="false"
      ref="selectVersion"
      @versionSelect="handleVersionSelect"
      :versions="avaliableVersions"
      v-if="avaliableVersions.length > 0"
      :handleMaskBtnClick="handleMaskBtnClick"
    ></SelectVersion>

    <VersionDescriptionDialog
      ref="versionDescriptionDialog"
      @apply="handleDialogApply"
    ></VersionDescriptionDialog>

    <!--联系我们-->
    <ContactUs ref="ContactUs"></ContactUs>
    <!--联系我们-->
    <ThreeYearShowShareCode
      ref="ThreeYearShowShareCode"
      :uuid="$route.params.uuid"
    ></ThreeYearShowShareCode>

    <!-- <template v-if="!isYunyingVersion && !$route.name.includes('share') && !isDesensitizationMode">

      <img
        src="@/assets/result/mobile-share-report.png"
        class="mobileCodeBtnShare shareCodeBtn"
        @click="handleShareCodeBtnWrapperClick"
        v-if="$route.query.openMode=='threeyears'"
      />


      <ShowShareCodeMobile
        :vertical="true"
        :is-mobile="true"
        ref="ShowShareCodeMobile"
        :companyName="companyName"
        v-else
        :uuid="uuid"
      ></ShowShareCodeMobile>


    </template> -->
  </div>
</template>
<script>
import specialResultHeadMixin from "@/pages/Result/specialResultHeadMixin";
import ThreeYearShowShareCode from "@/pages/Result/ThreeYearResult/components/ThreeShowShareCodeDialog";

var VueScrollTo = require("vue-scrollto");

import appendQuery from "append-query";
import { getPurchasedVersions } from "@/api/api";
import { mapState } from "vuex";
import BreadCrumb from "@/components/BreadCrumb";
import { downPDFurl, unlockHistoryTest, h5GetResultBasicInfo } from "@/api/api";
import TabContent from "./components/TabContent";
import DataSafeGuaranteeDialog from "./components/DataSafeGuaranteeDialog";
import TaxSummary from "./components/TaxSummary";
import SelectVersion from "@/pages/H5/Result/components/SelectVersion";
import VersionDescriptionDialog from "@/pages/FreeEdition/VersionDescriptionDialog";
import ContactDialog from "@/pages/FreeEdition/ContactDialog";
import OpsSection from "@/pages/H5/ResultEditor/components/OpsSection/OpsSection.vue";
import ContactUs from "@/pages/H5/Result/components/ContactUs";

import menuH5 from "./components/menuH5";
import ShowShareCodeMobile from "@/pages/Result/components/compents/ShowShareCodeMobile";

export default {
  name: "Result",
  components: {
    ThreeYearShowShareCode,
    BreadCrumb,
    TabContent,
    DataSafeGuaranteeDialog,
    VersionDescriptionDialog,
    ContactDialog,
    ShowShareCodeMobile,
    TaxSummary,
    SelectVersion,
    OpsSection,
    ContactUs,
    menuH5,
  },
  data() {
    return {
      showFloatPdf: false,
      avaliableVersions: [],
      currentSlideIndex: 0,
      showdelete: true,
      pdfurl: "",
      showclick: true,
      resultTabName: "综合风险评估",
      h5TitieName: "",
      companyName: "",
    };
  },
  props: {
    uuid: String,
  },
  mixins: [specialResultHeadMixin],
  beforeCreate() {},
  async mounted() {
    if (this.$route.query.token) {
      localStorage.setItem("authorization", this.$route.query.token);
    }
    await this.loadData();

    window.addEventListener("scroll", this.scrollEventHandler);

    //未购买或者是试用版启用遮罩
    this.isLocked();

    //弹出解锁对话框
    if (
      this.$route.query.showUnlock && //是否直接弹出
      this.$store.state.Result.showResultMask && //是否被锁了
      (await this.canUnlock()) //购买过了
    ) {
      this.showUnlockTip();
    }
    setTimeout(() => {
      const anchor = this.$route.query.anchor;
      this.$scrollTo(document.querySelector("#node" + anchor), 1000);
    }, 1000);
    this.getH5Title();
  },
  activated() {
    const anchor = this.$route.query.anchor;
    this.$scrollTo(document.querySelector("#node" + anchor), 1000);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEventHandler);
  },
  computed: {
    ...mapState(["companyInfo", "authorization"]),
    ...mapState("ResultEditor", [
      "tag",
      "maskStatus",
      "treeData",
      "discountPolicyTree",
      "downloadUrl",
    ]),
    //会否是运营版
    isYunyingVersion() {
      return this.$route.params.style === "tiyan";
    },
    dataLoaded() {
      //加载完成
      return Object.keys(this.treeData).length > 0;
    },
  },
  methods: {
    handleShareCodeBtnWrapperClick() {
      this.$refs["ThreeYearShowShareCode"].dialogVisible = true;
    },
    getH5Title() {
      let desensitization = Number(this.$route.query.desensitization) || 0;
      h5GetResultBasicInfo(this.uuid, desensitization).then((res) => {
        let data = res.data;
        this.companyName = data.companyName;
        //
        // if(this.$route.query.desensitization) {
        //   this.h5TitieName =
        //   data.desensitizationCompanyName + "·" + data.zqSection + "·" + data.editionName;
        // } else {
        this.h5TitieName =
          data.companyName + "·" + data.zqSection + "·" + data.editionName;
        // }
        //
      });
    },
    showMenuTree() {
      this.$refs.menuTree.h5 = true;
    },
    scrollEventHandler() {
      if (window.scrollY < 100) {
        this.showFloatPdf = false;
      } else {
        this.showFloatPdf = true;
      }
    },
    handleSloganClick() {
      this.$refs["versionDescriptionDialog"].dialogVisible = true;
    },
    handleDialogApply() {
      this.$refs["contactDialog"].dialogVisible = true;
    },
    switchTab(tabName, index) {
      this.resultTabName = tabName;
    },
    isDisplayTab(contrastKey) {
      if (contrastKey == "CW" || contrastKey == "SW") {
        return this.tag != "5";
      } else if (contrastKey == "FP") {
        return this.tag == "4" || this.tag == "5";
      }
    },
    // handleVersionSelect(version) {
    //   this.unlockHistoryTest(version);
    // },
    //提示解锁
    async showUnlockTip() {
      this.$confirm("是否解锁评测结果?(此操作会消耗规则库可用次数)", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.unlockHistoryTest();
        })
        .catch(() => {});
    },
    //遮罩上的解锁(查看更多)按钮按下后
    async handleMaskBtnClick() {
      const canUnlock = await this.canUnlock();

      if (canUnlock) {
        //如果已经买了,有解锁权限,显示解锁提示

        this.showUnlockTip();
      } else {
        //没买 提示购买
        this.showPurchaseTip();
      }
    },
    //去购买提示
    showPurchaseTip() {
      this.$alert("购买报税体检即可查看所有报告内容", "温馨提示", {
        type: "warning",
        confirmButtonText: "立即购买",
      }).then(() => {
        //设置支付后的跳转链接
        const redirectedURL = appendQuery(this.$route.fullPath, "showUnlock=1");
        sessionStorage.setItem("redirectedToAfterPaid", redirectedURL);

        this.$router.push({ name: "goods" });
      });
    },
    //判断是否可以直接解锁
    async canUnlock() {
      let token = JSON.parse(localStorage.getItem("token")).token;
      let res, purchasedVersions;
      try {
        res = await getPurchasedVersions({ token });
        purchasedVersions = res.data.list;
      } catch (error) {
        throw error;
      }
      if (purchasedVersions[this.tag]) {
        return true;
      } else {
        return false;
      }
    },
    //是否被锁了
    isLocked() {
      switch (this.maskStatus) {
        case "1": //正常
          this.$store.commit("Result/setShowResultMask", false);
          break;

        case "2": //没有版本库
        case "3": //没有风险库
        case "4": //都没有
        case "5": //体验版
          this.$store.commit("Result/setShowResultMask", true);
          break;
        default:
          this.$store.commit("Result/setShowResultMask", true);
      }
    },
    //初始化加载数据
    async loadData() {
      await this.$store.dispatch("ResultEditor/loadPDFEditorData", {
        nsrsbh: this.$route.query.nsrsbh,
        uuid: this.$route.params.uuid,
        action: this.$route.params.action,
        desensitization: this.$route.query.desensitization,
      });
    },
    // pdf下载
    dowmpdf() {
      var a = document.createElement("a");
      a.href = this.downloadUrl;
      a.download = "";
      // a.click()
      a.dispatchEvent(
        new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
        })
      );
    },
    handleNavClick(e) {
      if (e.target.classList.contains("item")) {
        const element = e.target;
        const parent = element.parentNode.children;
        this.currentSlideIndex = [...parent].indexOf(element);
      }
    },
    async unlockHistoryTest() {
      const tag = this.tag;
      let token = JSON.parse(localStorage.getItem("token")).token;
      const { bankId, nsrsbh, zq } = this.$route.query;
      let data = null;
      try {
        data = await unlockHistoryTest(this.uuid, token, bankId, nsrsbh, zq, tag);
      } catch (error) {
        throw error;
      }
      if (data.msgCode == 1) {
        //await this.loadData();
        this.$message({
          type: "success",
          message: "解锁成功!",
        });

        setTimeout(() => {
          location.reload();
        }, 1000);
      } else {
        this.$message({
          type: "error",
          message: data.msgInfo,
        });
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    //删除vuex中的检测发票结果缓存
    const { nsrsbh, uuid, zq, bankId } = this.$route.query;
    const ret = this.$store.cache.delete("Result/loadInvoice", {
      bankId,
      nsrsbh,
      zq,
      uuid,
    });
    next();
  },
};
</script>
<style lang="stylus" scoped>
@import '~@/styles/result.styl';

.result-wrapper {
  position: relative;
  background: #f8fbff;
  padding: 0.32rem 0.4rem 0.4rem 0.4rem;
  font-size: 0.16rem;
  font-family: PingFangSC-Semibold, PingFang SC;

  .title-menu {
    position: fixed;
    top: 0.56rem;
    right: 0.3rem;
    width: 0.88rem;
    height: 0.88rem;
    line-height: 0.88rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    font-size: 0.24rem;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #2F54EB;
    text-align: center;
    border-radius: 50%;
    z-index: 2000;
    font-weight: 600;
  }

  .title {
    font-size: 0.28rem;
    font-weight: 600;
    color: #2f54eb;
    line-height: 0.36rem;
    text-align: center;
  }
}

.result-wrapper-color {
  background-image: url('~@/assets/result/bg.png');
  background-color: #2F54EB;
}

.box-wrapper {
  border-radius: 0.16rem;
  // margin-bottom: 32px;
  overflow: hidden;

  .title {
    display: flex;
    align-items: center;
    font-size: 0.32rem;
    color: #2b2f58;
    line-height: 0.45rem;
    margin-top: 0.32rem;
    padding-left: 0.24rem;
    margin-bottom: 0.24rem;

    .tip {
      width: 0.12rem;
      height: 0.25rem;
      background: #2f54eb;
      margin-right: 0.1rem;
    }

    .title-l {
      width: 144px;
      margin-right: 24px;
    }

    .title-r {
      width: 144px;
      margin-left: 24px;
    }
  }
}

.floatSlogan {
  position: fixed;
  bottom: 70px;
  width: 308px;
  right: 28px;
  cursor: pointer;
}

.result {
  // background: #f8fbff;
  // padding: 0.32rem 0.4rem 0.4rem 0.4rem;
  // font-size: 0.16rem;
  // font-family: PingFangSC-Semibold, PingFang SC;
}

>>> .el-tabs__header {
  border-bottom: none;
  margin: 24px 0 16px;

  .el-tabs__nav-scroll {
  }

  .el-tabs__item {
    border: 0 none;
    line-height: 36px;
    height: 36px;
    margin-right: 16px;
  }

  .el-tabs__item.is-active {
    background: rgba(47, 84, 235, 1);
    border-radius: 18px;
    color: #fff;
  }

  .el-tabs__nav {
    border: 0 none;
  }
}

.title-content {
  // width: 1260px;
  padding: 10px 0;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
}

>>> .bread-crumb-wrapper {
  margin-top: 0;
}

.page-title-wrapper {
  padding: 0;
  padding-left: 30px;
  line-height: 28px;
}

.qymc {
  font-size: 20px;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
  line-height: 28px;
  margin: 8px 0 0 30px;
}

.qymc-title {
  display: flex;
}

.qyImg {
  width: 40px;
  height: 40px;
  margin-left: 24px;
}

.btn-download {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #2F54EB;
  cursor: pointer;
}

.floatDownloadPdf {
  width 84px;
  position fixed;
  right 57px;
  top 100px
  cursor pointer
}

>>> .mobileShareCodeBtnWrapper {
  right: 0.2rem;
  top: 1.5rem
  z-index: 2000;

  .shareCodeBtn {
    width 1.05rem
  }
}

.mobileCodeBtnShare {
  width 55px;
  position fixed;
  right 57px;
  top 100px
  cursor pointer
  right: 0.2rem;
  top: 1.5rem
  z-index: 2000;
}
</style>
