<template>
  <el-dialog
    title="产品兑换"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
  >
    <div>
      <el-form :model="form" :rules="rules" ref="form" label-width="100px">
        <el-form-item label="兑换码：" prop="cdKey">
          <el-input v-model="form.cdKey" class="input"></el-input>
          <button
            class="btn btn-primary btn-exchange"
            @click.prevent="submitForm('form')"
          >
            兑换
          </button>
        </el-form-item>
      </el-form>
      <div class="qr-wrapper">
        <img src="https://shuian-file.oss-cn-hangzhou.aliyuncs.com/image/qrcode_for_gh_cc017f82c39a_258.jpg" alt="" class="qr" />
        <div class="text">获取兑换码</div>
        <div class="text">联系电话：15381189712</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import {exchange} from "@/api/api";

export default {
  computed: {},
  data() {
    return {
      form: {
        cdKey: "",
      },
      error: "",

      dialogVisible: false,
      rules: {
        cdKey: [{ required: true, message: "请输入兑换码", trigger: "blur" }],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      let token = JSON.parse(localStorage.getItem("token")).token;
      const data = await exchange(token, this.form.cdKey);
      if (data.msgCode != 1) {

      } else {
        this.$alert(data.msgInfo);
        this.cdKey = "";
        this.dialogVisible = false;

      }

    },
  },
  async mounted() {},
};
</script>

<style lang="stylus" scoped>
.input {
  width: 300px;
}

.btn-exchange {
  width: 92px;
  height: 36px;
  background: linear-gradient(90deg, #5C8DF7 0%, #2F54EB 100%);
  border-radius: 16px !important;
  margin-left: 32px;
}

>>>.el-dialog__header {
  text-align: center;
  border-bottom: 1px solid #EEEEEE;
  padding: 16px 0 14px;

  .el-dialog__title {
    color: #2F54EB;
    font-weight: bold;
  }
}

>>> .el-dialog__body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
}

.qr-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .qr {
    width: 88px;
    height: 88px;
    background: #D8D8D8;
    border-radius: 8px;
    margin-bottom: 8px;
  }

  .text {
    line-height: 2;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }
}
</style>
