import { render, staticRenderFns } from "./SolidRing.vue?vue&type=template&id=1163a0e8&scoped=true"
import script from "./SolidRing.vue?vue&type=script&lang=js"
export * from "./SolidRing.vue?vue&type=script&lang=js"
import style0 from "./SolidRing.vue?vue&type=style&index=0&id=1163a0e8&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1163a0e8",
  null
  
)

export default component.exports