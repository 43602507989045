<template>
  <div class="wrapper">
    <div class="chart-wrapper">
      <PieChart :colors="colors" :data="chartData"></PieChart>
    </div>

    <div class="legends">
      <div class="legend" v-for="(item, index) in chartData" :key="index">
        <div class="title">
          <div class="box" :style="{ background: colors[index % colors.length] }"></div>
          <div>
            {{ item.name }}
          </div>
        </div>
        <div class="value">
          {{ (Math.round(item.value * 100 * 10000) / 10000).toFixed(2) }}%
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "./PieChart";

export default {
  name: "pie",
  props: {
    data: Object,
    colors: {
      type: Array,
      default() {
        return [
          "#EC5757",
          "#BCA88D",
          "#2588ED",
          "#F7A659",
          "#95A5CD",
          "#FF409A",
          "#B8D4F9",
          "#ABDE8A",
          "#EED9BE",
          "#6293F8",
          "#878787",
        ];
      },
    },
  },
  components: {
    PieChart,
  },
  computed: {
    chartData() {
      const ret = [];
      for (const i in this.data.xLabels) {
        const label = this.data.xLabels[i];
        const value = this.data.series[0].data[i];
        ret.push({ name: label, value });
      }
      return ret;
    },
  },
};
</script>

<style lang="stylus" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .chart-wrapper {
    display: flex;
    align-items: center;
  }

  .legends {
    flex-shrink: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .legend {
      width: 5.64rem;
      color: #666;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0.2rem;
      font-size: 0.24rem;

      .box {
        display: inline-block;
        width: 0.2rem;
        height: 0.2rem;
        background: red;
        margin-right: 0.1rem;
      }

      .title {
        display: flex;
        align-items: center;
      }

      .value {
        // vertical-align: top;
      }
    }
  }
}
</style>
