import { render, staticRenderFns } from "./WarningBadge.vue?vue&type=template&id=6698ad1f&scoped=true"
import script from "./WarningBadge.vue?vue&type=script&lang=js"
export * from "./WarningBadge.vue?vue&type=script&lang=js"
import style0 from "./WarningBadge.vue?vue&type=style&index=0&id=6698ad1f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6698ad1f",
  null
  
)

export default component.exports