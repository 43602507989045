<template>
  <div>
    <div class v-if="id==0"></div>
    <div class="badge red" v-else-if="id==1">高风险</div>
    <div class="badge yellow" v-else-if="id==2">中风险</div>
    <div class="badge green" v-else-if="id==3">低风险</div>
    <!--    <div class="badge grey" v-else-if="id==4">无风险</div>-->
  </div>
</template>

<script>
export default {
  props: {
    id: [Number, String],
  },
};
</script>

<style lang="stylus" scoped>
.badge {
  font-weight normal;
  // padding: 0.05rem 0.08rem;
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: inline-block;
  font-size: 0.24rem;
  margin-left: 0.2rem;
  width: 1.6rem;
  height: 0.44rem;
  line-height: 0.4rem;
  text-align: center;
  box-sizing: border-box;
}

.badge.red {
  color: rgba(245, 34, 45, 1);
  background: rgba(245, 34, 45, 0.08);
  border: 1px solid rgba(245, 34, 45, 1);
}

.badge.yellow {
  color: rgba(250, 173, 20, 1);
  border: 1px solid rgba(250, 173, 20, 1);
  background: rgba(250, 173, 20, 0.08);
}

.badge.green {
  color: rgba(82, 196, 26, 1);
  border: 1px solid rgba(82, 196, 26, 1);
  background: rgba(250, 173, 20, 0.08);
}

.badge.grey {
  color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.04);
}
</style>
