<template>
  <div class="tbc">
    <div class="sc" @click="switchScreen" v-if="labels.length > 3">
      <img src="@/assets/util/hScreen.png" alt="" />
    </div>
    <div class="table-c">
      <table class="table 111" cellpadding="6">
        <thead>
          <tr>
            <th v-for="(label, index) in labels" :key="index">
              {{ label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in tableData" :key="index">
            <td v-for="(col, index2) in row" :key="index2" @click="handleTdClick">
              <!-- <el-tooltip
                class="item"
                effect="light"
                :content="tipText(index, index2)"
                placement="top-start"
                :disabled="!tipText(index, index2)"
              > -->
              <span
                v-if="col == 'button{detail}'"
                class="clickable"
                @click="handleCellClick(index)"
                :class="{ 'text-has-tip': tipText(index, index2) }"
                ><a href="#" @click.prevent="handleToggleDetailCLick" class="link">{{
                  toggleLabel
                }}</a></span
              >
              <span
                v-else
                v-html="col"
                :class="{
                  'text-has-tip': tipText(index, index2),
                  'text-has-table': !!subTable(index, index2),
                }"
                @click="handleSubTableLinkClick(subTable(index, index2), index, index2)"
              ></span>
              <!-- </el-tooltip> -->
            </td>
          </tr>
        </tbody>
      </table>
      <div class="tbc-t">
        <el-drawer
          :title="subTableInDrawer ? subTableInDrawer.name : ''"
          :visible.sync="drawer"
          direction="btt"
          :before-close="handleClose"
          height="auto"
        >
          <div class="drawer-inner">
            <GenericTable v-if="generalTableData" :data="generalTableData"></GenericTable>
          </div>
        </el-drawer>
      </div>
    </div>

    <div class="full-sc">
      <el-drawer :visible.sync="horizontalScreen" direction="btt" size="100%">
        <div class="full-tab">
          <div class="t-w">
            <div class="titleName">{{ node.titleName }}</div>
            <WarningBadge :id="node.riskLevel"></WarningBadge>
          </div>
          <div class="dataText">{{ dataText }}</div>
          <div class="tab-s">
            <table class="tablefirst table-screen-b 111" cellpadding="6">
              <thead>
                <tr>
                  <th v-for="(label, index) in labels" :key="index">{{ label }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in tableData" :key="index">
                  <td v-for="(col, index2) in row" :key="index2" @click="handleTdClick">
                    <el-tooltip
                      class="item"
                      effect="light"
                      :content="tipText(index, index2)"
                      placement="top-start"
                      :disabled="!tipText(index, index2)"
                    >
                      <span
                        v-if="col == 'button{detail}'"
                        class="clickable"
                        @click="handleCellClick(index)"
                        :class="{ 'text-has-tip': tipText(index, index2) }"
                        ><a
                          href="#"
                          @click.prevent="handleToggleDetailCLick"
                          class="link"
                          >{{ toggleLabel }}</a
                        ></span
                      >
                      <span
                        v-else
                        v-html="col"
                        :class="{
                          'text-has-tip': tipText(index, index2),
                          'text-has-table': !!subTable(index, index2),
                        }"
                        @click="
                          handleSubTableLinkClick(subTable(index, index2), index, index2)
                        "
                      ></span>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import GenericTable from "@/pages/H5/SimpleResultEditor/components/InvoiceIndex/components/GenericTable";
import WarningBadge from "@/pages/H5/Result/components/compents/WarningBadge";

export default {
  components: {
    GenericTable,
    WarningBadge,
  },
  props: {
    data: Object,
    drawerTriggerIndex: Number,
    showDetails: Boolean,
    tips: Array,
    subTables: Array,
    dataText: [Array, String],
    node: [Object, Array],
  },
  computed: {
    tableData() {
      return this.data.data;
    },
    labels() {
      return this.data.labels;
    },
    toggleLabel() {
      return this.showDetails ? "收起" : "展开";
    },
    //通用表格
    generalTableData() {
      if (!this.subTableInDrawer) {
        return null;
      }
      const ret = [];
      for (let i = 0; i < this.subTableInDrawer.detailedTable.body.length; i++) {
        ret[i] = [];

        this.subTableInDrawer.detailedTable.header.forEach((key) => {
          ret[i].push(this.subTableInDrawer.detailedTable.body[i][key]);
        });
      }

      return {
        data: ret,
        labels: this.subTableInDrawer.detailedTable.header,
      };
    },
  },
  data() {
    return {
      drawer: false,
      subTableInDrawer: null,
      horizontalScreen: false,
    };
  },
  methods: {
    switchScreen() {
      this.horizontalScreen = true;
      this.$emit("showScreen");
    },
    handleTdClick() {},
    handleSubTableLinkClick(tableData, index, index2) {
      if (!tableData) {
        return;
      }
      this.subTableInDrawer = tableData;
      this.drawer = true;
    },
    handleClose() {
      this.drawer = false;
    },
    //点击打开表格
    subTable(row, col) {
      let ret = null;
      if (!this.subTables) {
        return null;
      }
      const table = this.subTables.find((v) => {
        let isShowTipInRow = false;
        if (v.pos[0] == -1 || v.pos[0] == row) {
          isShowTipInRow = true;
        }

        let isShowTipInCol = false;
        if (v.pos[1] == -1 || v.pos[1] == col) {
          isShowTipInCol = true;
        }
        if (isShowTipInRow && isShowTipInCol) {
          // debugger
        }
        return isShowTipInRow && isShowTipInCol;
      });
      if (table) {
        ret = {
          detailedTable: table.detailedTable,
          name: table.name,
        };
      }

      return ret;
    },
    //需要提示的文案
    tipText(row, col) {
      let ret = null;
      if (!this.tips) {
        return null;
      }
      const tip = this.tips.find((v) => {
        let isShowTipInRow = false;
        if (v.pos[0] == -1 || v.pos[0] == row) {
          isShowTipInRow = true;
        }

        let isShowTipInCol = false;
        if (v.pos[1] == -1 || v.pos[1] == col) {
          isShowTipInCol = true;
        }

        return isShowTipInRow && isShowTipInCol;
      });
      if (tip) {
        ret = tip.text;
      }
      return ret;
    },
    handleCellClick(row) {
      this.$emit("cellClick", row);
    },
    handleToggleDetailCLick() {
      this.$emit("update:showDetails", !this.showDetails);
    },
  },
};
</script>

<style lang="stylus" scoped>
.tbc {
  position: relative;
  padding: 0 0.24rem;

  .sc {
    position: absolute;
    right: 0.1rem;
    bottom: -0.16rem;
    width: 0.5rem;
    height: 0.5rem;
    line-height: 0.5rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.08rem;
    text-align: center;
    z-index: 1999;

    img {
      width: 0.24rem;
      height: 0.24rem;
      vertical-align: middle;
    }
  }
}

.table-c {
  width: 100%;
  overflow-x: auto;
  max-height: 400px;
}

.drawer-inner {
  overflow-y auto;
  padding-top 0;
  box-sizing: border-box
}

.text-has-tip {
  // text-decoration underline;
  cursor pointer
}

.text-has-table {
  color blue
  cursor pointer
  text-decoration underline;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  // margin-top: 22px;
  width: 100%;
  overflow-x: auto;

  td, th {
    text-align: left;
    font-size: 0.2rem;
    // padding: 0.12rem 0.12rem;
    // border: 1px solid rgba(238, 238, 238, 1);
    color: rgba(0, 0, 0, 0.6);
    // vertical-align: top;

    box-sizing: border-box;
    white-space: nowrap;
    min-width: 60px;
    max-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

.tablefirst {
  border-collapse: collapse;
  border-spacing: 0;

  td, th {
    text-align: left;
    font-size: 0.2rem;
    padding: 0.12rem 0.12rem;
    color: rgba(0, 0, 0, 0.6);

    box-sizing: border-box;
    white-space: nowrap;
    min-width: 60px;
    max-width: 5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    .clickable {
      color: #2F54EB;
      cursor: pointer;
    }
  }

  th {
    font-weight: 600;
    background: rgba(240, 245, 255, 1);
  }

  tr:nth-child(even) {
    td {
      background: rgba(245, 245, 245, 1);
    }
  }

  .link {
    color: rgba(47, 84, 235, 1);
  }
}

.table tr:nth-child(2n-1) td {
  background: #fff;
}

.table thead tr > th:first-child,
.table tbody tr > td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  max-width: 2.3rem;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
  // white-space: normal;
}

// .tablefirst thead tr > th:first-child,
// .tablefirst tbody tr > td:first-child {
//   position: auto;
// }



>>> :focus {
  outline: none;
}

.tbc-t {
  .el-drawer, .btt {
    height: 100% !important;
  }
}

.full-sc {
  // >>> .el-drawer__wrapper {
  //   z-index: 3008 !important;
  // }
  .full-tab {

    .t-w {
      display: flex;
      align-items: center;
    }

    transform-origin: center center;
    transform: rotateZ(90deg);

    .titleName {
      font-size: 0.28rem;
      font-weight: 600;
      color: #2B2F58;
      line-height: 0.36rem;
    }

    .dataText {
      font-size: 0.28rem;
      color: #40496A;
      line-height: 0.36rem;
      margin: 12px 0;
    }
  }
  >>> .el-drawer__header {
    padding: 0;
  }
  >>> .el-drawer__body {
    overflow: hidden;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  >>> .el-drawer__open .el-drawer.btt {
    border-radius: 0;
  }

  >>> .el-drawer__close-btn {
    font-size: 30px;
    position: relative;
    right: 10px;
    top: 10px;
  }

  // display: flex;
  // align-items: center;
  // justify-content: center;
  // flex-direction: column;

  .tab-s {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // flex-direction: column;
    // height: 100%;

    max-height: 220px;
    max-width: 500px;
    overflow: auto;

    .tablefirst {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .tablefirst tr:nth-child(2n-1) td {
      background: #fff;
    }

    .tablefirst thead tr > th:first-child,
    .tablefirst tbody tr > td:first-child {
      position: -webkit-sticky;
      position: sticky;
      left: 0;
    }

    .table-screen-b {
      td, th {
        min-width: 80px;
        // max-width: 200px;
      }
    }
  }
}
</style>
