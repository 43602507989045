<template>
  <div>
    <div class="chartColumn" id="chartColumn" ref="chartColumn"></div>
  </div>
</template>

<script>
import echarts from "echarts";
export default {
  mounted() {
    this.showMap();
  },
  props: {
    node: Object,
  },
  computed: {
    data() {
      return this.node;
    },
    //行业参考值的名字
    industryReferenceValueLabel() {
      return this.data.header[2];
    },
    //主要的指标名字
    mainBarLabel() {
      return this.data.header[1];
    },
  },
  data() {
    return {
      x: [],
      rects: [],
      lines: new Array(2),
    };
  },
  methods: {
    showMap() {
      this.$refs["chartColumn"].style.width = "310px"; //window.innerWidth * 0.5 + "px";
      this.$refs["chartColumn"].style.height = "350px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);

      this.x = this.data.body.map((v) => v[""]);

      this.rects = this.data.body.map((v) => v[this.mainBarLabel]);
      this.lines[0] = this.data.body[0][this.industryReferenceValueLabel];
      this.lines.fill(this.data.body[1][this.industryReferenceValueLabel], 1);

      const option = {
        tooltip: {
          formatter: (params) => {
            const text = params[0].seriesName.includes("行业")
              ? ""
              : `${params[0].seriesName}:${params[0].value}%`;

            return text;
          },
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        legend: {
          x: "center",
          top: "0%",
          data: [this.mainBarLabel, this.industryReferenceValueLabel],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "15%",
          top: "15%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [this.x[0], this.x[1]],
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "solid",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#666",
              },
            },
            axisLabel: {
              // rotate: 35,
              interval: 0,
              textStyle: {
                fontSize: 11,
                fontFamily: "PingFangSC-Regular,PingFang SC",
                color: "rgba(0,0,0,0.6)",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",

            axisLine: {
              show: true,
              lineStyle: {
                color: "#666",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: { show: true, formatter: "{value} %" },
          },
          {
            type: "value",
            name: "",

            axisLine: {
              show: false,
              lineStyle: {
                color: "#666",
              },
            },
            axisTick: {
              // 坐标轴 刻度
              show: false, // 是否显示
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#EEEEEE",
                width: 1,
                type: "dashed",
              },
            },
            axisLabel: { show: false },
          },
        ],
        series: [
          {
            name: this.mainBarLabel,
            type: "bar",

            center: ["50%", "1%"],
            color: ["#5B8FF9"],
            barWidth: "30",
            data: this.rects,
            label: {
              show: true,
              position: "outside",
            },
          },
          {
            name: this.industryReferenceValueLabel,
            type: "line",
            color: ["#61D7A7"],
            data: this.lines,
          },
        ],
      };

    
      this.chartColumn.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chart-wrapper {
  background: #fff;

  .chart {
    .chartColumn {
      >>>div:first-child {
        width: 100% !important;
      }
    }
  }
}
</style>