<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import echarts from "echarts";

export default {
  mounted() {
    this.showMap();
  },
  props: {
    colors: Array,
    data: Array,
  },
  methods: {
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = "340px"; //window.innerWidth * 0.5 + "px";
      this.$refs["chartColumn"].style.height = "250px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);
      let option = [];
      if (this.data.length > 0) {
        option = {
          legend: {
            show: false,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {d}%'
          },
          series: [
            {
              name: "风险占比",
              type: "pie",
              radius: ["30%", "60%"],
              avoidLabelOverlap: false,
              color: this.colors,
              label: {
                show: true,
                normal: {
                  show: true,
                  formatter: "{d}%",
                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
              data: this.data,
            },
          ],
        };
      } else {
        option = {
          legend: {
            show: false,
          },
          tooltip: {
            trigger: 'item',
            formatter: '{a} <br/>{b} : {d}%'
          },
          series: [
            {
              name: "风险占比",
              type: "pie",
              radius: ["30%", "60%"],
              avoidLabelOverlap: false,
              color: ["#aaa"],
              label: {
                show: true,
                normal: {
                  show: true,
                  formatter: "{d}%",
                },
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: "12",
                  fontWeight: "bold",
                },
              },
              data: [{
                name: "无数据",
                value: 0
              }],
            },
          ],
        };
      }

      this.chartColumn.setOption(option);
    },
  },
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 340px;
  height: 250px;
}
</style>
