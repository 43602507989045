<template>
  <div class="wrapper">
    <div v-for="grp in node.children" :key="grp.id">
      <div class="grp" :id="'node' + grp.id">
        <div class="grp-title" :class="{ displayNone: grp.titleName == '' }">
          <span class="red">{{ grp.titleName }}</span>
        </div>
        <div class="grp-background" v-for="node in grp.children" :key="node.id">
          <!--发票-->
          <DropToggle v-if="node.type == 1" :id="'node' + node.id">
            <template v-slot:title>
              <span class="title-name">{{ node.titleName }}</span>

              <WarningBadge :id="node.riskLevel"></WarningBadge>
            </template>
            <template v-slot:body>
              <InvoiceIndex :node="node"></InvoiceIndex>
            </template>
          </DropToggle>
          <!--/发票-->
          <!--税务-->
          <DropToggle v-if="node.type == 2" :id="'node' + node.id">
            <template v-slot:title>
              <span class="title-name title-name-width">{{ node.titleName }}</span>

              <WarningBadge :id="node.riskLevel"></WarningBadge>
            </template>
            <template v-slot:body>
              <RiskProblemList :node="node"></RiskProblemList>
            </template>
          </DropToggle>
          <!--/税务-->
          <!--财务-->
          <DropToggle v-if="node.type == 4" :id="'node' + node.id">
            <template v-slot:title>
              <span class="title-name">{{ node.titleName }}</span>

              <WarningBadge :id="node.riskLevel"></WarningBadge>
            </template>
            <template v-slot:body>
              <TaxIndex :node="node"></TaxIndex>
            </template>
          </DropToggle>
          <!--/财务-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RiskProblemList from "@/pages/H5/Result/components/RiskProblemList";
import TaxIndex from "@/pages/H5/ResultEditor/components/TaxIndex";
import InvoiceIndex from "@/pages/H5/ResultEditor/components/InvoiceIndex/InvoiceIndex";
import DropToggle from "./DropToggle";
import WarningBadge from "./compents/WarningBadge";
export default {
  components: {
    DropToggle,
    InvoiceIndex,
    WarningBadge,
    RiskProblemList,
    TaxIndex,
  },
  props: {
    node: Object,
  },
};
</script>

<style lang="stylus" scoped>
.wrapper{
}
.grp {

  .grp-title {
    font-size: 0.28rem;
    color: #2B2F58;
    line-height: 0.40rem;
    padding: 0.24rem;
    padding-top: 0;
    font-weight: 600;
  }

  .grp-background {
    background: #FFFFFF;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
    border-radius: 0.14rem;
    margin-bottom: 0.24rem;
  }

  .title-name {
    max-width: 3.12rem;
    word-wrap: break-word
  }

  .title-name-width {
    max-width: 6.2rem;
  }
}
</style>
