import { render, staticRenderFns } from "./SolidPie.vue?vue&type=template&id=99f08522&scoped=true"
import script from "./SolidPie.vue?vue&type=script&lang=js"
export * from "./SolidPie.vue?vue&type=script&lang=js"
import style0 from "./SolidPie.vue?vue&type=style&index=0&id=99f08522&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "99f08522",
  null
  
)

export default component.exports