<template>
  <div class="tbc-t menu-scroll">
    <el-drawer title="目录" :visible.sync="h5" direction="btt">
      <MenuTree :data="treeData" target="treeData" :key="0"></MenuTree>
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MenuTree from "../../ResultEditor/components/MenuTree";

export default {
  data() {
    return {
      h5: false,
    };
  },
  computed: {
    ...mapState("ResultEditor", ["treeData"]),
  },
  components: {
    MenuTree,
  },
};
</script>

<style lang="stylus" scoped>
.menu-scroll {
  font-size: 0.32rem;
  font-weight: 600;
  color: #2B2F58;
}

.menu-scroll .el-drawer,.menu-scroll .btt {
  height: auto !important;
  max-height: 400px !important;
}
</style>
