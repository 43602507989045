<template>
  <div>
    <div ref="chartColumn" class="chartColumn"></div>
  </div>
</template>

<script>
//进销项
import { mapState } from "vuex";
import echarts from "echarts";
export default {
  props: {
    data: Array,
  },
  async mounted() {
    const data = this.data;

    //X轴标签
    this.xLabels = data.map((item) => {
      return `${item.year}.${item.month}`;
    });

    //进项税额
    this.jxjes = data.map((item) => {
      return item.jxje;
    });

    //进项税额(已抵扣)
    this.deductedJxjes = data.map((item) => {
      return item.deductedJxje;
    });

    //销项税额
    this.xxjes = data.map((item) => {
      return item.xxje;
    });

    this.showMap();
  },
  data() {
    return {
      xLabels: [],
      jxjes: [],
      deductedJxjes: [],
      xxjes: [],
    };
  },
  methods: {
    FontChart(res) {
      //获取到屏幕的宽度
      var clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return; //报错拦截：
      let fontSize = 10 * (clientWidth / 1920);
      return res * fontSize;
    },
    //echart
    showMap() {
      this.$refs["chartColumn"].style.width = "6.5rem"; //window.innerWidth * 0.5 + "px";
      this.chartColumn = echarts.init(this.$refs["chartColumn"]);

      const option = {
        tooltip: {
          trigger: "axis",
          confine: true,
        },
        legend: {
          x: "center",
          // bottom: "0%",
          data: ["进项税额", "进项税额(已抵扣)", "销项税额"],
          textStyle: {
            fontSize: this.FontChart(3.5), //图例文字字体大小
            // color: "#8A90A3", //图例文字颜色
          },
        },
        grid: {
          left: "3%",
          right: "3%",
          top: "20%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xLabels,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "solid",
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
          axisLabel: {
            rotate: 35,
            interval: 0,
            textStyle: {
              fontSize: 11,
              fontFamily: "PingFangSC-Regular,PingFang SC",
              color: "rgba(0,0,0,0.6)",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLine: {
            show: true,
            lineStyle: {
              color: "#666",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EEEEEE",
              width: 1,
              type: "dashed",
            },
          },
          axisLabel: { show: true, formatter: "{value}" },
        },
        series: [
          {
            name: "进项税额",
            type: "line",
            data: this.jxjes,
            color: ["#2F54EB"],
            symbol: "circle",
            symbolSize: 7,
          },
          {
            name: "进项税额(已抵扣)",
            type: "line",
            data: this.deductedJxjes,
            color: ["#FAAD14"],
            symbol: "circle",
            symbolSize: 7,
          },
          {
            name: "销项税额",
            type: "line",
            data: this.xxjes,
            color: ["#52C41A"],
            symbol: "circle",
            symbolSize: 7,
          },
        ],
      };

      this.chartColumn.setOption(option);
    },
  },
  computed: {
    ...mapState(["Result"]),
  },
};
</script>

<style lang="stylus" scoped>
.chartColumn {
  width: 6.5rem;
  height: 4.8rem;
  // height: 435px;
  z-index: 1001;
}
</style>
